import React from "react"

import * as styles from "./NewsTitle.module.scss"

const NewsTitle = ({children}) => (

    <div className={styles.NewsTitle}>
        <h1>{children}</h1>
    </div>

)

export default NewsTitle