import React from "react"

import * as styles from "./ArticleMeta.module.scss"

const ArticleMeta = (props) => (
    <div className={styles.ArticleMeta}>
        <div>Written by {props.author}</div>
        <div>{props.date}</div>
    </div>
)

export default ArticleMeta
