import React from "react"
import withPreview, { getContent } from "../apollo/preview"
import { getNewsPreview as PREVIEW_QUERY } from "../preview-queries/newsPreview"

// Data
import { graphql } from "gatsby"

// Component
import Layout from "../components/Global/Layout"
import Main from "../components/Shared/Main"
import Article from "../components/Shared/Article"
import Content from "../components/Shared/Content"
import CentredContentWithSidebar from "../components/Shared/CentredContentWithSidebar"
import ArticleHeroImage from "../components/Shared/ArticleHeroImage"
import HeroAreaSmall from "../components/Shared/HeroAreaSmall"
import NewsTitle from "../components/News/NewsTitle"
import ArticleMeta from "../components/Shared/ArticleMeta"
import Sidebar from "../components/Shared/Sidebar"
import CTA from "../components/Shared/CTA"
import PersonCTA from "../components/Shared/PersonCTA"
import ShareLinks from "../components/Shared/ShareLinks"

import { venueForPage } from "../helpers/venueForPage"
import SEOSettings from "../components/Shared/SEOSettings"

const NewsTemplate = (props) => {
    const page = getContent(props, "news")

    const postAuthor = (page) => {
        if (page.news_fields.author) return page.news_fields.author.title

        if (!page.author.firstName || page.author.firstName.length === 0) return null

        return `${page.author.firstName} ${page.author.lastName}`
    }

    const postDate = (page) => {
        if (page.news_fields.articleDate) return page.news_fields.articleDate

        const dateTime = new Date(page.date)

        return dateTime.toLocaleString("en-GB", { day: "numeric", month: "long", year: "numeric" })
    }

    return (
        <Layout
            venue={venueForPage({ page: page, menus: props.data.menus })}
            currentLocation={props.path}
            currentTemplate="templateNewsArticle"
            title={page.title}
        >
            <SEOSettings entity={page} />
            <HeroAreaSmall title="News" backlink="/news" image={{ sourceUrl: props.pageContext.heroImageUrl.sourceUrl, title: `${page.title} image` }} />

            <Main>
                <CentredContentWithSidebar>
                    <Content>
                        <Article>
                            <NewsTitle>{page.title}</NewsTitle>
                            <ArticleHeroImage {...page.image.image} />
                            <ShareLinks url={page.link} excerpt={page.excerpt} />

                            {postAuthor && <ArticleMeta author={postAuthor(page)} date={postDate(page)} />}

                            <div dangerouslySetInnerHTML={{ __html: page.content }}></div>
                        </Article>
                    </Content>

                    <Sidebar>
                        {page.sidebar_calls.sidebarCtas && page.sidebar_calls.sidebarCtas.map((cta) => <CTA key={cta.id} {...cta} />)}

                        {page.person_ctas.people && page.person_ctas.people.map((person) => <PersonCTA key={person.id} {...person} />)}
                    </Sidebar>
                </CentredContentWithSidebar>
            </Main>
        </Layout>
    )
}

export const pageQuery = graphql`
    query NewsQuery($databaseId: Int!) {
        menus: allWpMenu {
            results: nodes {
                ...PageMenusNode
            }
        }

        news: wpNews(databaseId: { eq: $databaseId }) {
            id
            title
            publishToLive {
                publishToLive
            }
            content
            date
            link
            excerpt
            image: hero_fields {
                image {
                    sourceUrl
                    title
                }
            }
            author {
                node {
                    firstName
                    lastName
                }
            }
            news_fields {
                articleDate
                author {
                    ... on WpPerson {
                        title
                        databaseId
                    }
                }
            }
            ...NewsCtas
            ...NewsPersonCtas
            seo {
                metaDesc
                title
                opengraphTitle
                opengraphDescription
                metaKeywords
                metaRobotsNofollow
                metaRobotsNoindex
                opengraphImage {
                    sourceUrl
                }
            }
        }
    }
`

export default withPreview({ preview: PREVIEW_QUERY })(NewsTemplate)
