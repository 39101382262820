import React from "react"
import withPreview from "../../apollo/preview"
import { getNewsPreview as PREVIEW_QUERY } from "../../preview-queries/newsPreview"

import {NewsTemplate} from "../../templates/news"

const UnpublishedNewsPreview = (props) => {
    if(!props || !props.preview) return <></>

    const previewObjectProps = {...props}
    previewObjectProps.data = {
        wpgraphql: {
            news: props.preview.news.revisions.edges[0].node,
            menus: {
                results: []
            }
        }
    }
    
    return <NewsTemplate {...previewObjectProps} />
}

export default withPreview({ preview: PREVIEW_QUERY })(UnpublishedNewsPreview)
