import React from "react"

import * as styles from "./ArticleHeroImage.module.scss"
import PlaceholderImage from "./PlaceHolderImage"
import Image from "./Image";
import {imageSizes} from "../../helpers/imgix";

const ArticleHeroImage = props => {
  return (
    <>
      <div className={styles.ArticleHeroImage}>
        {props.sourceUrl && (
          <Image url={props.sourceUrl} alt={props.title} sizes={imageSizes.hero} />
        )}
      </div>
    </>
  )
}

export default ArticleHeroImage
