import React from "react"

import * as styles from "./CentredContentWithSidebar.module.scss"

const CentredContentWithSidebar = ({children}) => (

    <div className={styles.CentredContentWithSidebar}>
        {children}
    </div>

)

export default CentredContentWithSidebar